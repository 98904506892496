<template>
  <div class="voucherManagement">
    <div class="facilityBox">
      <!-- 搜索条件 -->
      <el-form ref="searchForm" inline :model="searchForm">
        <el-form-item label="代收人：" label-width="80px" prop="name">
          <el-input
            v-model.trim="searchForm.name"
            placeholder="请输入代收人名称"
            clearable
            @clear="(pagination.page = 1), getVoucherList()"
          ></el-input>
        </el-form-item>

        <el-form-item label="手机号：" label-width="80px" prop="phone">
          <el-input
            v-model.trim="searchForm.phone"
            placeholder="请输入代收人手机号"
            clearable
            @clear="(pagination.page = 1), getVoucherList()"
          ></el-input>
        </el-form-item>

        <el-button
          type="primary"
          @click="(pagination.page = 1), getVoucherList()"
          icon="el-icon-search"
          >搜索</el-button
        >
        <el-button
          type="primary"
          @click="$refs['searchForm'].resetFields()"
          icon="el-icon-delete"
          >清空</el-button
        >
      </el-form>

      <!-- 表格 -->
      <el-table
        :data="pagination.list"
        style="width: 100%"
        :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        v-loading="pagination.loading"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="RowIndex" label="序号" align="center">
        </el-table-column>
        <el-table-column prop="DriverName" label="代收人" align="center">
        </el-table-column>
        <el-table-column prop="TelPhone" label="手机号" align="center">
        </el-table-column>
        <el-table-column prop="FolderCount" label="凭证总数" align="center">
        </el-table-column>
        <el-table-column prop="BindCount" label="未关联" align="center">
        </el-table-column>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              style="color: #0F5FFF;"
              @click="toDetail(scope.row.UserID)"
              >查看</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        class="pagination"
        background
        @current-change="handleCurrentChange"
        :current-page.sync="pagination.page"
        :page-size="pagination.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="pagination.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { GetOwNerEmListAsync } from "@/api/voucherManagement/voucherManagement.js";
export default {
  data() {
    return {
      searchForm: {
        name: "", // 代收人名称
        phone: "", // 代收人手机号
      },
      pagination: {
        loading: false,
        page: 1,
        pagesize: 10,
        total: 0,
        list: [],
      },
    };
  },
  created() {
    this.getVoucherList();
  },
  methods: {
    clearSearch() {},

    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getVoucherList();
    },

    // 获取凭证列表
    getVoucherList() {
      this.pagination.loading = true;
      let params = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        driverName: this.searchForm.name,
        driverPhone: this.searchForm.phone,
      };
      GetOwNerEmListAsync(params)
        .then((res) => {
          let { TotalCount, DataList } = res.data;
          this.pagination.total = TotalCount;
          this.pagination.list = DataList;
        })
        .finally(() => {
          this.pagination.loading = false;
        });
    },

    // 隔行变色
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "";
      } else {
        return "statistics-warning-row";
      }
    },

    // 去详情页
    toDetail(id) {
      this.$router.push({
        path: "/waybill/voucherManagement/voucherDetail",
        query: {
          userId: id,
        },
      });
    },
  },
};
</script>

<style>
.el-table__row.statistics-warning-row {
  background: #f3f8ff;
}
</style>

<style lang="scss" scoped></style>
